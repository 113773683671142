@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  
  .animate-marquee {
    animation: marquee 20s linear infinite;
  }

  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .animate-blink {
    animation: blink 1.5s infinite;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-slide-up {
    animation: slide-up 1s forwards;
  }

  .flutterwave-button {
    animation: blink 1s infinite alternate;
  }

  @keyframes blink {
    0% {
      background-color: blue;
    }
    100% {
      background-color: red;
    }
  }
